import React, { useRef } from 'react';
import './App.css';

function App() {
    const audioRef = useRef(null);

    const playSound = () => {
        const audio = audioRef.current;
        audio.currentTime = 0; // Reset the audio to start from the beginning
        audio.play();
    };

    return (
        <div className="App">
            <header className="App-header">
                <h1>Play Sound on Button Click</h1>
                <button onClick={playSound}>Play Sound</button>
                <audio ref={audioRef}>

                    <source src="/nope.wav" type="audio/wav" />
                    Your browser does not support the audio element.
                </audio>
            </header>
        </div>
    );
}

export default App;